<template>
  <div id="login">
    <div class="min-vh-100 px-4 text-center d-flex justify-content-center flex-column">
      <div class="row justify-content-center text-start">
        <div class="col-12 col-md-3 col">
          <div>
            <form @submit.prevent>
              <h2 class="text-center">Create Account</h2>
              <div class="row mb-3">
                <div class="col">
                  <label for="firstname" class="form-label">First Name</label>
                  <input v-model.trim="signupForm.firstname" type="text" placeholder="John" id="firstname" class="form-control"/>
                </div>
                <div class="col">
                  <label for="lastname" class="form-label">Last Name</label>
                  <input v-model.trim="signupForm.lastname" type="text" placeholder="Smith" id="lastname" class="form-control"/>
                </div>
              </div>
              <div class="mb-3">
                <label for="email2" class="form-label">Email</label>
                <input v-model.trim="signupForm.email" type="text" placeholder="john.smith@gmail.com" id="email2" class="form-control"/>
              </div>
              <div class="mb-3">
                <label for="password2" class="form-label">Password</label>
                <input v-model.trim="signupForm.password" type="password" placeholder="Really strong password!" id="password2" class="form-control"/>
              </div>
              <div class="d-grid gap-2 mb-3">
                <button @click="signup()" class="btn btn-primary text-white">Sign Up</button>
                <router-link to="/login" class="btn btn-link text-body text-decoration-none">Back to Log In</router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SIGNUP } from '@/store/actions.type'

export default {
  data () {
    return {
      signupForm: {
        firstname: '',
        lastname: '',
        email: '',
        password: ''
      }
    }
  },
  methods: {
    signup () {
      this.$store.dispatch(SIGNUP, {
        email: this.signupForm.email,
        password: this.signupForm.password,
        firstname: this.signupForm.firstname,
        lastname: this.signupForm.lastname
      })
    }
  }
}
</script>
