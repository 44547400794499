<template>
  <div id="login">
    <div class="min-vh-100 px-4 text-center d-flex justify-content-center flex-column">
      <div class="row justify-content-center text-start">
        <div class="col-12 col-md-3 col">
          <div class="modal fade" ref="resetPasswordModal">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="my-0">Reset Password</h5>
                  <button type="button" class="btn-close" @click="modal.hide()"></button>
                </div>
                <div class="modal-body">
                  <div v-if="!showSuccess">
                    <form @submit.prevent class="mb-3">
                      <p class="form-label">Enter your email to reset your password</p>
                      <input v-model.trim="email" type="email" placeholder="you@email.com" class="form-control"/>
                    </form>
                    <p v-if="errorMsg !== ''" class="error mb-3">{{ errorMsg }}</p>
                    <div class="d-grid gap-2 mb-3">
                      <button @click="resetPassword()" class="btn btn-primary text-white">Reset</button>
                    </div>
                  </div>
                  <p v-else>Success! Check your email for a reset link.</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <form @submit.prevent>
              <div class="mb-3">
                <label for="email1" class="form-label">Email</label>
                <input v-model.trim="loginForm.email" type="text" placeholder="you@email.com" id="email1" class="form-control" />
              </div>
              <div class="mb-3">
                <label for="password1" class="form-label">Password</label>
                <input v-model.trim="loginForm.password" type="password" placeholder="******" id="password1" class="form-control" />
                <a class="form-text" @click="modal.show()">Forgot Password?</a>
              </div>
              <div class="d-grid gap-2 mb-3">
                <button @click="login()" class="btn btn-primary text-white">Log In</button>
                <router-link to="/signup" class="btn btn-link text-body text-decoration-none">Create an Account</router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/firebase'
import { Modal } from 'bootstrap'

export default {
  data () {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      email: '',
      showSuccess: false,
      errorMsg: '',
      modal: null
    }
  },
  mounted () {
    this.modal = new Modal(this.$refs.resetPasswordModal)
  },
  methods: {
    login () {
      this.$store.dispatch('login', {
        email: this.loginForm.email,
        password: this.loginForm.password
      })
    },
    async resetPassword () {
      this.errorMsg = ''
      try {
        await auth.sendPasswordResetEmail(this.email)
        this.showSuccess = true
      } catch (err) {
        this.errorMsg = err.message
      }
    }
  }
}
</script>
